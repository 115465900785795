
import { BaseInfoServerReponse } from "../model/server-reposnse/base-info-server-reposnse"
import { BaseInfoModel } from "../model/client-model/base-info-model"
import { AssetsServerReponse } from "../model/server-reposnse/assets.server_reponse"
import { AssetsModel } from "../model/client-model/assets-model"
import { NetworksModel } from "../model/client-model/networks-model"
import { AssetsMapperResult } from "../model/client-model/mappet-result-model"
import { GenerateAddressServerReponse } from "../model/server-reposnse/generate_address_server_response"
import { GenerateAddress } from "../model/client-model/generate-address-model"

export class CGPayMapper {

    static baseInfo = (data: BaseInfoServerReponse): BaseInfoModel => {
        return {
            logoUrl: data.merchantBaseInfo.logoUrl,
            merchantName: data.merchantBaseInfo.merchantName,
            orderNo: data.orderNo,
            returnUrl: data.returnUrl,
            terminalName: data.merchantBaseInfo.terminalName,
            tokenExpiration: data.tokenExpiration
        } as BaseInfoModel
    }

    static generateAddress = (data: GenerateAddressServerReponse): GenerateAddress => {
        return {
            cryptoAddress: data.cryptoAddress,
            memoRegex: data.additionalData.memoRegex,
            memoType: data.additionalData.memoType
        } as GenerateAddress
    }


    static assets(data: AssetsServerReponse): AssetsMapperResult {
        const assets: AssetsModel[] = [];
        const networks: NetworksModel[] = [];

        for (const currency of data.currencies) {
            assets.push({
                isDefault: currency.isDefault,
                id: currency.id,
                name: currency.name,
                code: currency.code,
                availableNetworks: currency.availableNetworks
            });

            const currencyNetworks: NetworksModel = {
                currencyId: currency.id,
                networks: currency.availableNetworks.map(network => ({
                    isDefault: network.isDefault,
                    networkId: network.id,
                    networkName: network.name,
                }))
            };

            networks.push(currencyNetworks);
        }

        return {
            currencies: assets,
            networks: networks
        }
    }






}